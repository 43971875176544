import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isTableBlockLarge } from '../../../helpers';

import PortfolioBlock from './PortfolioBlock/PortfolioBlock';
import './PortfolioTable.scss';

export default class PortfolioTable extends Component {
  static propTypes = {
    items: PropTypes.array,
    featuredItem: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.componentRef = React.createRef();
  }

  componentDidMount() {
    this.setBlockHeight();
  }

  setBlockHeight() {
    const elements = [
      ...this.componentRef.current.querySelectorAll('.c-portfolio-block__data'),
    ].slice(1);

    const maxHeight = Math.max.apply(
      Math,
      [...elements].map((element) => element.clientHeight),
    );

    const paddingWidth = 80;
    const elementHeight = paddingWidth + maxHeight + 'px';

    [...elements].forEach((element) => {
      element.parentNode.parentNode.style.height = elementHeight;
    });
  }

  render() {
    const { items = [], featuredItem = {} } = this.props;

    return (
      <div ref={this.componentRef} className="c-portfolio-table">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 c-portfolio-table__content">
              <div className="row">
                <div className="col-xs-12">
                  <div className="c-portfolio-table__item c-portfolio-table__item--first-child">
                    {featuredItem && (
                      <PortfolioBlock
                        item={featuredItem}
                        type={featuredItem.type}
                        size="large"
                        featured
                      />
                    )}
                  </div>
                </div>
                {items.map((item, index) => {
                  const large = isTableBlockLarge(index + 3);
                  return (
                    <div
                      className={`col-xs-12 col-sm-6 col-md-${large ? 8 : 4}`}
                      key={index}
                    >
                      <div className="c-portfolio-table__item">
                        <PortfolioBlock
                          item={item}
                          type={item.type}
                          size={large ? 'large' : 'small'}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
