import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Utils from '../../../../utils';
import {
  HEXtoRGBA,
  getImgixUrl,
  createCanonicalUrl,
} from '../../../../helpers';
import clientConfig from '../../../../clientConfig';
import Link from '../../../shared/Link/Link';
import LeadGenBadge from '../../../shared/LeadGenBadge/LeadGenBadge';

import './PortfolioBlock.scss';

export class PortfolioBlock extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    size: PropTypes.oneOf(['small', 'large']),
    featured: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      details: this.getDetails(),
      backgroundColor: this.getBackgroundColor(),
      backgroundImage: this.getBackgroundImage(),
    };
  }

  getDetails() {
    const { type, item, featured } = this.props;

    const details = {};

    switch (type) {
      case 'event':
        details.type = 'event';
        details.tenant = item.tenant;
        details.domain = item.domain;
        details.path = item.details.path + '/';
        details.heading = item.details.name;
        details.addressCity = item.details.address.city;
        details.formattedDate = Utils.getEventDates(
          item.details.date.startDate,
          item.details.date.endDate,
          clientConfig.defaultLanguage,
        );
        details.valueProposition = item.details.valueProposition;
        details.label = featured ? 'FEATURED' : 'UPCOMING EVENT';
        details.startDate = item.details.date.startDate;
        details.endDate = item.details.date.endDate;
        details.addressName =
          item.details.address.name +
          (item.details.address.city ? ', ' + item.details.address.city : '');
        details.address = item.details.address.address;
        break;

      case 'article':
        details.type = 'article';
        details.path = item.articlePath + '/';
        details.heading = item.title;
        details.label = item.primaryTopic || 'ARTICLE';
        details.gated = item.gated;
        details.contentType = item.contentType;
        break;

      default:
        break;
    }

    return details;
  }

  getBackgroundColor() {
    const { type, item } = this.props;

    if (type === 'event') {
      return HEXtoRGBA(item.brand.colours.primary, 1);
    } else if (type === 'article') {
      return HEXtoRGBA('#000000', 0.6);
    } else {
      return 'transparent';
    }
  }

  getBackgroundImage() {
    const { type, item, pageConfig: { tenantId } = {} } = this.props;

    if (type === 'event') {
      return getImgixUrl(tenantId, item.backgroundImage?.path, 'w=800');
    } else if (type === 'article') {
      return getImgixUrl(tenantId, item.imageUrl, 'w=800');
    } else {
      return null;
    }
  }

  render() {
    const {
      details: {
        type,
        domain,
        tenant,
        path,
        heading,
        label,
        formattedDate,
        startDate,
        endDate,
        address,
        addressCity,
        addressName,
        valueProposition,
        description,
        gated,
        contentType,
      },
      backgroundImage,
      backgroundColor,
    } = this.state;

    const {
      featured = false,
      size = 'small',
      pageConfig: { parentDomain } = {},
    } = this.props;

    return (
      <Link
        to={path}
        sub={domain}
        tenant={tenant}
        parent={type === 'article'}
        className={`c-portfolio-block c-portfolio-block--${type} ${size} ${
          featured ? 'featured' : ''
        }`}
      >
        <div
          className="c-portfolio-block__background-image lazy"
          data-background-image={backgroundImage}
        />
        <div
          className="c-portfolio-block__background-overlay"
          style={{ backgroundColor }}
        />
        <div className="c-portfolio-block__background-gradient" />
        <div className="c-portfolio-block__content">
          <div className="c-portfolio-block__badge">
            {gated && <LeadGenBadge content={contentType} />}
          </div>
          <div className="c-portfolio-block__data">
            <div className="c-portfolio-block__left">
              {label && <div className="c-portfolio-block__label">{label}</div>}
              {heading && (
                <h3 className="c-portfolio-block__heading">{heading}</h3>
              )}
              {description && (
                <div className="c-portfolio-block__description">
                  {description}
                </div>
              )}
              {featured && formattedDate && (
                <div className="c-portfolio-block__date">{formattedDate}</div>
              )}
            </div>
            <div className="c-portfolio-block__right">
              {!featured && formattedDate && (
                <div className="c-portfolio-block__date">{formattedDate}</div>
              )}
              {addressCity && (
                <p className="c-portfolio-block__address">{addressCity}</p>
              )}
              {featured && valueProposition && (
                <div>
                  <p className="c-portfolio-block__value-proposition">
                    {valueProposition}
                  </p>
                  <div className="c-portfolio-block__upcoming-event-button">
                    <i className="fa fa-arrow-circle-right" /> GO TO SITE
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {type === 'event' && (
          <div itemScope itemType="http://schema.org/Event">
            <meta itemProp="name" content={heading} />
            <meta itemProp="startDate" content={startDate} />
            <meta itemProp="endDate" content={endDate} />
            <meta
              itemProp="url"
              content={createCanonicalUrl(path, domain, parentDomain)}
            />
            <div
              itemProp="location"
              itemScope
              itemType="http://schema.org/Place"
            >
              <meta itemProp="name" content={addressName} />
              <meta itemProp="address" content={address} />
            </div>
          </div>
        )}
      </Link>
    );
  }
}

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(PortfolioBlock);
