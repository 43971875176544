import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getImgixUrl } from '../../../helpers';
import Link from '../../shared/Link/Link';
import './PortfolioHero.scss';

export function PortfolioHero(props) {
  const {
    portfolio: { data: { name, heroSection = {} } = {} } = {},
    pageConfig: { tenantId } = {},
  } = props;

  const cta = heroSection.callToAction || {};
  const backgroundImage = getImgixUrl(tenantId, heroSection.background?.path);

  return (
    <div
      className="portfolio-hero lazy"
      data-background-image={backgroundImage}
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="portfolio-hero__heading">
              <div className="portfolio-hero__heading-name">{name}</div>
              <div className="portfolio-hero__heading-title">
                {heroSection.valueProposition}
              </div>
              <div className="portfolio-hero__heading-subtitle">
                {heroSection.subTitle}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {heroSection.tiles
            .filter((tile) => tile.shown)
            .map((tile, i) => (
              <div key={i} className="col-xs-12 col-sm-6 col-md-3">
                <div className="portfolio-hero__tile">
                  <div className="portfolio-hero__tile-large">{tile.large}</div>
                  <div className="portfolio-hero__tile-small">{tile.small}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {cta.bannerText && (
        <div className="portfolio-hero__banner">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <div className="portfolio-hero__banner-text">
                  {cta.bannerText}
                </div>
              </div>
              <div className="col-xs-12 col-sm-6">
                <div className="text-right">
                  {cta.buttonHref && (
                    <Link
                      to={cta.buttonHref}
                      type="button"
                      target="_self"
                      className="portfolio-hero__banner-cta"
                      label={cta.buttonText}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

PortfolioHero.propTypes = {
  portfolio: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(PortfolioHero);
