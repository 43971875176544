import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HubLayout from '../../../layouts/HubLayout/HubLayout';
import PortfolioHero from '../../../sections/PortfolioHero/PortfolioHero';
import PortfolioTable from '../../../sections/PortfolioTable/PortfolioTable';

export function HubPortfolioView(props) {
  const {
    portfolio,
    portfolio: {
      data: { featuredEvent = {}, portfolioLinkViews = [] } = {},
      meta: { title } = {},
    } = {},
  } = props;

  return (
    <HubLayout helmet={{ title }}>
      <PortfolioHero portfolio={portfolio} />
      <PortfolioTable items={portfolioLinkViews} featuredItem={featuredEvent} />
    </HubLayout>
  );
}

HubPortfolioView.propTypes = {
  portfolio: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    portfolio: state.portfolio,
  };
}

export default connect(mapStateToProps)(HubPortfolioView);
