import React from 'react';
import PropTypes from 'prop-types';

import './LeadGenBadge.scss';

export default function LeadGenBadge(props) {
  const { withBorder, content } = props;
  const icon =
    content === 'VIDEO'
      ? 'play_arrow'
      : content === 'AUDIO'
      ? 'volume_up'
      : content === 'AGENDA_BROCHURE' ||
        content === 'SPEX_BROCHURE' ||
        content === 'WHITEPAPER'
      ? 'get_app'
      : '';

  return (
    <span
      className={`lead-gen-badge ${
        withBorder ? 'lead-gen-badge--with-border' : ''
      }`}
    >
      <div className="lead-gen-badge__text">Premium</div>
      {icon && <i className="material-icons lead-gen-badge__icon">{icon}</i>}
    </span>
  );
}

LeadGenBadge.propTypes = {
  content: PropTypes.string,
  withBorder: PropTypes.bool,
};
